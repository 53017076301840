<template>
  <div class="about">
    <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in swipeImg" :key="index"><img :src="item" width="100%" height="100%"></van-swipe-item>
    </van-swipe>
    <div class="info">
      <van-row>
        <van-col span="12" class="border-right"><span v-text="count.online"></span><p>在线</p></van-col>
        <van-col span="12"><span >{{count.total}}</span><p>全部</p></van-col>
      </van-row>
    </div>
    <div class="dev-list">
      <div v-if="!devEmpty">
          <div v-if="devList.length != 0">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false">
                <div class="block" v-for="(item,index) in devList" :key="index" >
                  <van-row :class="{
                      'flex-end':true,
                      'online':item.devStatus == 'online',
                      'offline':item.devStatus == 'offline',
                      'alarm':item.devStatus == 'alarm'
                    }">
                      <van-col span="6" @click="goDetail(item)">
                          <img :src="
                            item.devType === 'camera' ? require('@/assets/swjl/img/camera.png') :  require('@/assets/swjl/img/default.png')
                          " width="60px" height="70px">
                      </van-col>
                      <van-col span="15" @click="goDetail(item)">
                          <div class="title" v-text="item.devName"></div>
                          <div v-text="item.location || '设备暂未设置地址'"></div>
                      </van-col>
                      <van-col  span="3" @click="goBatLine(item)">
                        <div class="other-msg"> 
                          <van-icon name="chart-trending-o" />
                          <span> {{item.comment ? (item.comment/1000).toFixed(2) : 0.0}}V</span>
                      </div>
                       
                      </van-col>
                  </van-row>
                </div>
            </van-list>
          </div>
          <div v-else><van-loading class="z-loading" size="24px" style="margin:20vh 0" vertical>数据努力加载中...</van-loading></div>
      </div>
      <div v-else><van-empty description="设备列表为空" /></div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'devIndex',
  components: {
  },
  data(){
    return{
      swipeImg:[
        require('@/assets/swjl/img/banner1.jpg'),
        require('@/assets/swjl/img/banner2.jpg'),
        require('@/assets/swjl/img/banner3.jpg')
      ],
      count:{
        online:0,
        total:0
      },
      projectCode:'',
      loading:false,
      finished:false,
      pageSize:10,
      pageNum:1,
      devList:[],
      devEmpty:false,
      isLoading: false,
    }
  },
  methods:{
      // 点击设备进入详情页面
      goDetail(item){
        window.sessionStorage.setItem('dev-info',JSON.stringify(item))
        // this.$router.push({path:'/swjl/detail',query: {devCode:item.devCode}})
        this.$router.push({path:'/swjl/detail'})
      },
       // 点击设备进入电量曲线页面
       goBatLine(item){
        window.sessionStorage.setItem('dev-info',JSON.stringify(item))
        // this.$router.push({path:'/swjl/detail',query: {devCode:item.devCode}})
        this.$router.push({path:'/swjl/batline'})
      },
      get4gCameraList(){
        this.$api.SWJL.cameraInfo({projectCode:this.projectCode, devType: "camera"}).then( d =>{
          if(d.length > 0){
            this.devList = [...this.devList,...d];
            this.finished = true;
          }else{
            if(this.devList.length == 0){
              this.devEmpty = true;
            }
           
          }
          this.count = {
            online:this.devList.filter( v => v.devStatus !== 'offline').length,
            total:this.devList.length,
          }
          this.devList.sort((a, b) => {
            if (a.devStatus > b.devStatus) {
              return -1;
            }
            if (a.devStatus < b.devStatus) {
              return 1;
            }
            return 0;
          });
        })
      },
      // 获取分页数据信息
      onLoad(){
        this.$api.SWJL.getAllDev({projectCode:this.projectCode, devType: "3320" }).then( d =>{
          if(d.length > 0){
            this.loading = false;
            this.devList = d;
            
            this.finished = true;
            
          }else{
           // this.devEmpty = true;
          }
          this.get4gCameraList();
          // this.count = {
          //   online:d.filter( v => v.devStatus !== 'offline').length,
          //   total:d.length,
          // }
        })
      },
      
  },
  mounted(){
      this.projectCode = window.sessionStorage.getItem('projectCode');
      this.onLoad();
  }
}
</script>
<style lang="scss" scoped>
.info{
    padding: 4%;
    background: #2bae85;
    color: white;
    margin-top: -2%;
    .border-right{border-right:1px solid #989898ad}
    span{font-size: 1.5rem;font-weight: bold;}
    p{margin: 0;font-size: .9rem;}
}
.dev-list{
    margin-bottom: 22%;
    .block{
        border: 1px solid #f5f5f5;
        background-color: white;
        padding: 4%;
        margin-bottom: 10px;
        font-size: .9rem;
        text-align: left;
        box-shadow: 0 2px 4px #e4e4e4;
        .online{color:#2bae85;}
        .offline{color:#5d5e5f;}
        .alarm{color:#de0101;}
        img{display: block;margin: 0 auto;}
        .title{
            font-weight: bold;
            margin-bottom: 20px;
        }
        .other-msg{
          text-align: center;
        }
        span{font-size: .9rem;
          font-weight: bold;text-align: center;}
        van-icon {margin: 0;font-size: 1.5rem;}
    }
    .flex-end{
      display: flex;
      align-items: center;
    }
}
</style>